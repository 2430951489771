import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { MdArrowBackIosNew } from 'react-icons/md';
import { FaMoneyCheck, FaUser } from 'react-icons/fa';
import {
  useNavigate as navigation,
  useLocation,
} from 'react-router-dom';
import { SkeletonLoading } from '../Loading/Loading';

const DetailInfo = () => {
  const location = useLocation();
  const listview = location.state?.Full_Name || {};
  const [isLoading,setLoading]=useState(false)
  const [mealPlans, setMealPlan] = useState([]);
  const nav = navigation();
  const [transactionStatus, setTransactionStatus] = useState(
    mealPlans.Transaction_id !== 'null' ? mealPlans.Transaction_id : ''
  );
  const token = localStorage.getItem('token');

  const handleClick = (e) => {
    e.preventDefault();
    nav('/dash/mealorder');
  };
  
  const handleDetailFetch = useCallback(() => {
    setLoading(true);
    const config = {
      method: 'get',
      url: `https://api.maednutritions.com/api/Meal_Plan/${listview.id}`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        setMealPlan(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, [listview.id,token]);

useEffect(() => {
  handleDetailFetch();
}, [handleDetailFetch]);
   

  return (
    <div>
      <div className="flex items-center text-xl mt-4">
        {' '}
        <MdArrowBackIosNew
          onClick={handleClick}
          className="mx-11 m-5 cursor-pointer transition-all text-2xl hover:text-green-500"
        />
      </div>
      <div className="flex bg-white rounded-md p-10 m-11 h-full">
        <div className="flex w-full items-start">
          <div className="space-y-4 flex-1 text-base">
            <div className="text-slate-500 space-y-2">
              <FaMoneyCheck className="text-4xl" />
              <h1 className="text-xl font-semibold">
                Subscription Information
              </h1>
            </div>
            {isLoading ? (
              <div className="w-full h-full items-center">
                <SkeletonLoading />
              </div>
            ) : (
                mealPlans.map((mealPlan) => {
                  // const mealPlanId = mealPlan.id;
                  const isMealPrep = mealPlan.Hight && mealPlan.Weight !== '';
                  const transUpdateUrl = isMealPrep
                    ? `https://api.maednutritions.com/api/Meal_Prep/Transaction/${mealPlan.id}`
                    : `https://api.maednutritions.com/api/Meal_Plan/Transaction/${mealPlan.id}`;
                  const handleSave = () => {
                    setLoading(true);
                    const config = {
                      method: 'patch',
                      url: transUpdateUrl,
                      headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                      },
                      data: {
                        Transaction_id: transactionStatus,
                      },
                    };

                    axios(config)
                      .then(function (response) {
                        console.log(response.data);
                        handleDetailFetch();
                        setLoading(false);
                      })
                      .catch(function (error) {
                        console.log(error);
                        setLoading(false);
                      });
                  };
                  return (
                    <div className="text-lg space-y-2">
                      <h1>
                        Customer ID |{' '}
                        <span className="text-primary font-semibold">
                          {listview.id}
                        </span>
                      </h1>
                      <h1>
                        Subscription |{' '}
                        <span className="text-primary font-semibold">
                          {mealPlan.Hight && mealPlan.Weight !== ''?'Monthly':mealPlan.Subscription}
                        </span>{' '}
                      </h1>
                      <h1>
                        Meal Perday |{' '}
                        <span className="text-primary font-semibold">
                          {mealPlan.Meal_PerDay}
                        </span>
                      </h1>
                      <h1>
                        Bank Name |{' '}
                        <span className="text-primary font-semibold">
                          {mealPlan.Hight && mealPlan.Weight !== ''
                            ? mealPlan.Bank
                            : mealPlan.Bank_Name}
                        </span>
                      </h1>
                      <h1>
                        Meal Type |{' '}
                        <span className="text-primary font-semibold">
                          {mealPlan.Hight && mealPlan.Weight !== ''?mealPlan.Meal_Type:mealPlan.Meal_type}
                        </span>
                      </h1>
                      <h1>
                        Status |{' '}
                        <span className="text-primary font-semibold">
                          {mealPlan.Status}
                        </span>
                      </h1>
                      <div className="flex items-center space-x-4">
                        <h1>Transaction |</h1>
                        <input
                          type="text"
                          onChange={(e) => setTransactionStatus(e.target.value)}
                          className="text-primary font-semibold border-b-2 border-gray-400 focus:ring-0 focus:border-primary placeholder-gray-500 bg-transparent py-1 px-2 outline-none flex-grow"
                          placeholder={
                            mealPlan.Transaction_id !== 'null'
                              ? mealPlan.Transaction_id
                              : 'Enter Transaction Status'
                          }
                        />

                        <button
                          onClick={handleSave}
                          className="bg-primary text-white px-2 text-sm py-2 rounded hover:bg-secondary transition-colors duration-200 ease-in-out"
                        >
                          Save
                        </button>
                      </div>
                      {mealPlan.Hight && mealPlan.Weight !== '' && (
                        <>
                          <h1>
                            Height |{' '}
                            <span className="text-primary font-semibold">
                              {mealPlan.Hight}
                            </span>
                          </h1>
                          <h1>
                            Weight |{' '}
                            <span className="text-primary font-semibold">
                              {mealPlan.Weight}
                            </span>
                          </h1>
                          <h1>
                            Target |{' '}
                            <span className="text-primary font-semibold">
                              {mealPlan.Target}
                            </span>
                          </h1>
                          <h1>
                            Exercise Range |{' '}
                            <span className="text-primary font-semibold">
                              {mealPlan.Exercise}
                            </span>
                          </h1>
                        </>
                      )}
                      <h1>
                        Price |{' '}
                        <span className="text-primary font-semibold">
                          {mealPlan.Total_Price} ETB
                        </span>
                      </h1>
                      <h1>
                        Subscription Date |{' '}
                        <span className="text-primary font-semibold">
                          {mealPlan.Subscription_Date}
                        </span>
                      </h1>
                    </div>
                  );
                })
            )}
          </div>
          <div className="w-0.5 rounded-xl mx-8 h-96 bg-primaryLight" />
          <div className="space-y-4 flex-1 text-xl">
            <div className="text-slate-500 space-y-2">
              <FaUser className="text-4xl" />
              <h1 className="text-xl font-semibold">Customer Information</h1>
            </div>
            <div className="space-y-2 text-lg">
              <h1>
                Full Name |{' '}
                <span className="text-primary font-semibold">
                  {listview.Full_Name}
                </span>
              </h1>
              <h1>
                Phone Number |{' '}
                <span className="text-primary font-semibold">
                  {listview.Phone_Number}
                </span>
              </h1>
              <h1>
                Allergic |{' '}
                <span className="text-primary font-semibold">
                  {listview.Allergy}
                </span>
              </h1>
              <h1>
                Fasting Type |{' '}
                <span className="text-primary font-semibold">
                  {listview.Fasting_type}
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailInfo;
