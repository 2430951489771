import { useState as usestate } from 'react';
import axois from 'axios';
import { AiOutlineUser } from 'react-icons/ai';
import { AiOutlineLock } from 'react-icons/ai';
import { useNavigate as nav } from 'react-router-dom';
import logo from '../Assets/logo/Logo_wordmark.png'
import { DotLoading } from '../Components/Loading/Loading';

const loginform = () => {
  const navigate = nav();
  const [username, setUsername] = usestate('');
  const [password, setPassword] = usestate('');
  const [isUsernameFocused, setIsUsernameFocused] = usestate(false);
  const [isPasswordFocused, setIsPasswordFocused] = usestate(false);
  const [isLoading,setIsLoading]=usestate(false)
  const [error,setError]=usestate('')


  const handleUsernameFocus = () => {
    setIsUsernameFocused(true);
    setIsPasswordFocused(false);
  };

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
    setIsUsernameFocused(false);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if(username===''||password==='')
    {
      setError('Please provide the required information to proceed')
    }else
    {
    setError('')
    setIsLoading(true)
    try {
      const response = await axois.post(
        'https://api.maednutritions.com/api/Login',
        {
          User_Name: username,
          Password: password,
        }
      );
      
      const token = response.data.Token;
      localStorage.setItem('token', token);
      console.log(token)
      setIsLoading(false)
      navigate('/dash');
    } catch (error) {
      setIsLoading(false)
      setError('Something went wrong, please check your credentials, or check that you are connected to internet!')
      console.log(error);
    }
  }
  };

  return (
    <div className="flex flex-col justify-center items-center space-y-5 ease-out duration-700">
      <div className='text-2xl text-center flex w-full flex-col items-center gap-4'>
        <img className='w-20' src={logo} alt="" />
        <h1 className='font-thin'>Maed Nutrition <br />
         <span className='font-semibold text-xl'>Admin Panel</span>
          </h1>
      </div>
      <h1 className="text-xl text-gray-600 font-light">Login</h1>
      <form action="" onSubmit={handlesubmit} className="w-full sm:max-w-md">
        <div className="space-y-3">
          <div
            className={`flex items-center border rounded-lg px-3 py-2 ease-out duration-500 ${
              isUsernameFocused ? 'border-primary' : 'border-gray-500'
            } hover:border-primary`}
          >
            <span
              className={`text-gray-500 mr-2 ${
                isUsernameFocused ? 'text-primary' : ''
              }`}
            >
              <AiOutlineUser className="h-4 w-4" />
            </span>
            <input
              type="text"
              placeholder="Username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="border-none text-sm focus:outline-none flex-grow bg-transparent"
              onFocus={handleUsernameFocus}
            />
          </div>
          <div
            className={`flex items-center border rounded-lg px-3 py-2 ease-out duration-500 ${
              isPasswordFocused ? 'border-primary' : 'border-gray-500'
            } hover:border-primary`}
          >
            <span
              className={`text-gray-500 mr-2 ${
                isPasswordFocused ? 'text-primary' : ''
              }`}
            >
              <AiOutlineLock className="h-4 w-4" />
            </span>
            <input
              type="text"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border-none text-sm focus:outline-none flex-grow bg-transparent"
              onFocus={handlePasswordFocus}
            />
          </div>
        </div>
        <div className='text-sm text-red-500 font-normal'>{error}</div>
        <div className="flex flex-col mt-7">
          <button
            type="submit"
            className={`w-full sm:w-72 border ease-out duration-500 ${isLoading?'bg-primary hover:bg-primary cursor-wait':'bg-primary hover:bg-neutral-300'} font-normal text-base py-2 rounded-lg p-1 transition-all  hover:text-slate-900 text-white`}
          >
            {
              isLoading?<div className='flex w-full justify-center py-2'><DotLoading/></div>:'Login'
            }
            
          </button>
        </div>
      </form>
    </div>
  );
};

export default loginform;
