import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { CircleLoading } from '../Components/Loading/Loading';
import { BiError } from 'react-icons/bi';
import { RiRefreshLine } from 'react-icons/ri';
import { BsClipboard2Data } from 'react-icons/bs';
import axios from 'axios';

const Orderlist = ({
  listView,
  listtype,
  mealPlan,
  isLoading,
  error,
  refresh,
}) => {
  const navigation = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const token = localStorage.getItem('token');

  const sortedItems = listView.sort(
    (a, b) => b.id - a.id
  );

  const filteredItems = sortedItems.filter((list) => {
    const regex = new RegExp(searchTerm, 'i');
    return regex.test(list.Full_Name);
  });

  return (
    <div className="">
      <div className="flex justify-between w-full items-end px-10 pt-8">
        <div className="flex justify-start w-1/3 bg-white items-center border border-gray-300 p-2 space-x-2 rounded-lg">
          <FaSearch className="text-gray-400" />
          <input
            type="text"
            placeholder="Search Customers"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full outline-none"
          />
        </div>
        <div className="flex gap-8 items-center">
          <button
            className="text-sm flex items-center gap-1 hover:text-primary transition-all font-semibold text-slate-800"
            onClick={refresh}
          >
            <RiRefreshLine
              className={`${isLoading ? 'animate-spin' : ''} text-xl`}
            />{' '}
            Refresh Data
          </button>
          <h1 className="font-semibold text-slate-400">
            {' '}
            Sorted by latest Orders on Top
          </h1>
        </div>
      </div>
      <div className="flex flex-col p-8 curser-pointer">
        <div className="flex w-full justify-between items-start text-gray-100 bg-secondary p-5 rounded-lg cursor-pointer ease-linear duration-200">
          {listtype.map((items) => (
            <div>
              <h1 className="w-full">{items.header}</h1>
            </div>
          ))}
        </div>
        {isLoading ? (
          <div className="w-full flex justify-center h-full gap-3 mt-20 items-center flex-col">
            <CircleLoading />
            <h1>LOADING DATA</h1>
          </div>
        ) : error !== '' ? (
          <div className="w-full flex justify-center h-full gap-3 mt-20 items-center flex-col">
            <BiError className="text-5xl text-red-500" />
            <h1>There was an error loading your data</h1>
          </div>
        ) : filteredItems.length === 0 ? (
          <div className="w-full flex justify-center h-full gap-3 mt-20 items-center flex-col">
            <BsClipboard2Data className='w-24 h-24 text-slate-300'/>
            <h1>There is no data</h1>
          </div>
        ) : (
          filteredItems.map((list) => {
            const mealPlanForCustomer = mealPlan.find(
              (plan) => plan.Customer_id === list.id
            );
            const isSeen =
              mealPlanForCustomer && mealPlanForCustomer.View === 'Seen';
            const isMealPrep =
              mealPlanForCustomer &&
              mealPlanForCustomer.Hight &&
              mealPlanForCustomer.Weight !== '';

            const ReadUrl = isMealPrep
              ? `https://api.maednutritions.com/api/Meal_Prep/View/${list.id}`
              : `https://api.maednutritions.com/api/Meal_Plan/View/${list.id}`;

            const DeleteUrl = `https://api.maednutritions.com/api/Customer/${list.id}`;

            const handleOpen = () => {
              const config = {
                method: 'patch',
                url: ReadUrl,
                headers: {
                  Authorization: 'Bearer ' + token,
                  'Content-Type': 'application/json',
                },
                data: {
                  View: isSeen ? 'Unseen' : 'Seen',
                },
              };

              axios(config)
                .then(function (response) {
                  console.log(response.data);
                  navigation(`/Detailinfo/${list.id}`, {
                    state: { Full_Name: list },
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            };

            const handleDelete = (e) => {
              e.preventDefault();
              e.stopPropagation();
              const config = {
                method: 'delete',
                url: DeleteUrl,
                headers: {
                  Authorization: 'Bearer ' + token,
                  'Content-Type': 'application/json',
                },
              };

              axios(config)
                .then(function (response) {
                  console.log(response.data);
                  refresh();
                })
                .catch(function (error) {
                  console.log(error);
                });
            };
            return (
              <div key={list.id}>
                <div
                  onClick={handleOpen}
                  className={`flex justify-between items-center p-5 my-4 rounded-lg cursor-pointer hover:bg-gray-300 ease-linear duration-200 ${
                    !isSeen ? 'bg-secondary' : 'bg-gray-200'
                  }`}
                >
                  <h1 className="w-20">{list.id}</h1>
                  <div className="w-0.5 rounded-xl h-10 bg-gray-500" />
                  <h1 className="w-20">{list.Full_Name}</h1>
                  <div className="w-0.5 rounded-xl h-10 bg-gray-500" />
                  <h1 className="w-20">{list.Phone_Number}</h1>
                  <div className="w-0.5 rounded-xl h-10 bg-gray-500" />
                  <h1 className="w-20">{list.Place_of_Delivery}</h1>
                  <div className="w-0.5 rounded-xl h-10 bg-gray-500" />
                  <h1 className="w-28">{list.createdAt}</h1>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={(e) => handleDelete(e)}
                      className="flex z-50 justify-end px-5 p-2 bg-red-600 text-white font-light text-sm rounded-md hover:bg-red-700 ease-linear duration-200"
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <div className="flex justify-end"></div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
export default Orderlist;
