import {useEffect } from 'react'
import { BrowserRouter, Routes, Route ,useParams} from 'react-router-dom';
import Loginpage from './Components/Loginpage/loginpage';
import Navigation from './Components/NavBar/Navigation';
import Dashboard from './Components/Dashboard/Dashboard';
import Orders from './Components/Order/Orders';
import Customerhandle from './Controller/customerHandler'
import DetailInfo from './Components/Detail/DetailInfo';

function App() {
  return (
    <div className="overflow-x-hidden">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Loginpage />} />
          <Route path="/dash" element={<Navigation />}>
            <Route index element={<Dashboard />} />
            <Route path='mealorder' element={<Orders />} />
          </Route>
          <Route path='/handler' element={<Customerhandle />} />
          <Route path='/Detailinfo/:listid' element={<DetailInfo />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
