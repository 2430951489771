import React from 'react';

export const CircleLoading = () => {
  return (
    <div className='load'></div>

  );
};

export const DotLoading =()=>{
    return (
        <div class="dots">
        <div></div>
        <div></div>
        <div></div>
      </div>
      
    );
}

export const SkeletonLoading =()=>{
    return (
      <div className="flex flex-col w-60 sm:w-80 animate-pulse h-96">
        <div className="flex-1 space-y-4">
          <div className="w-3/6 h-6 rounded dark:bg-gray-300" />
          <div className="w-3/4 h-6 rounded dark:bg-gray-300" />
          <div className="w-3/6 h-6 rounded dark:bg-gray-300" />
          <div className="w-3/7 h-6 rounded dark:bg-gray-300" />
          <div className="w-3/4 h-6 rounded dark:bg-gray-300" />
          <div className="w-2/3 h-6 rounded dark:bg-gray-300" />
          <div className="w-3/4 h-6 rounded dark:bg-gray-300" />
          <div className="w-3/7 h-6 rounded dark:bg-gray-300" />
        </div>
      </div>
    );
}
