import { useState , useEffect } from 'react'
import OrderList from '../../Reuseables/Orderlist';
import axios from 'axios'

const MealPlan = () => {
  const [listview, setListview] = useState([]);
  const [mealPlan, setMealPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchData =async() => {
    const token = localStorage.getItem('token');
    setIsLoading(true);

    const config = {
      method: 'get',
      url: 'https://api.maednutritions.com/api/Customer',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    };

    await axios(config)
      .then(function (response) {
        setListview(response.data);
        setIsLoading(false);
        setError('');
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setError(error);
      });
    
   const config_1 = {
     method: 'get',
     url: `https://api.maednutritions.com/api/Meal_Plan/`,
     headers: {
       Authorization: 'Bearer ' + token,
       'Content-Type': 'application/json',
     },
   };
   const config_2 = {
     method: 'get',
     url: `https://api.maednutritions.com/api/Meal_Prep/`,
     headers: {
       Authorization: 'Bearer ' + token,
       'Content-Type': 'application/json',
     },
   };
axios(config_1)
  .then(function (response) {
    console.log(response.data);
    setMealPlan((prevMealPlan) => [...prevMealPlan, ...response.data]);
  })
  .catch(function (error) {
    console.log(error);
  });

axios(config_2)
  .then(function (response) {
    console.log(response.data);
    setMealPlan((prevMealPlan) => [...prevMealPlan, ...response.data]);
  })
  .catch(function (error) {
    console.log(error);
  });
  
     const config_3 = {
       method: 'get',
       url: `https://api.maednutritions.com/api/Meal_plan/all`,
       headers: {
         Authorization: 'Bearer ' + token,
         'Content-Type': 'application/json',
       },
     };
     axios(config_3)
       .then(function (response) {
         console.log(response.data);
         
       })
       .catch(function (error) {
         console.log(error);
       });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const list = [
    { header: 'ID' },
    { header: 'Customer Name' },
    { header: 'Phone-Number' },
    { header: 'Customer Location' },
    { header: 'Order Date' },
    { header: '' },
  ];

  return (
    <div className="flex flex-col ">
      <OrderList
        error={error}
        listtype={list}
        listView={listview}
        mealPlan={mealPlan}
        isLoading={isLoading}
        refresh={fetchData}
      />
    </div>
  );
};
export default MealPlan;