import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Widgets from '../../Reuseables/Widgets';
import { AiOutlineUser } from 'react-icons/ai';
import BarMealChart from '../../Reuseables/BarGraph';
import {MdOutlineRestaurantMenu} from 'react-icons/md'
import {GiMeal} from 'react-icons/gi'

const Dashboard = () => {
  const [customer, setCustomer] = useState([]);
  const [mealprep, setMealprep] = useState([]);
  const [mealplan, setMealplan] = useState([])

  useEffect(() => {
   handleCustomer()
   handleMealPlan()
   handleMealprep()
  }, []);
  const handleCustomer = () =>{
    const token = localStorage.getItem('token');
    console.log(token);
    // var axios = require('axios');
    var config = {
      method: 'get',
      url: 'https://api.maednutritions.com/api/Report/Customer',
      headers: {
        Authorization: 'Beare ' + token,
        'Content-Type': 'application/json',
      },
    };

    axios(config)
      .then(function (response) {
        setCustomer(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const handleMealPlan = () =>{
    const token = localStorage.getItem('token');
    console.log(token);
    // var axios = require('axios');
    var config = {
      method: 'get',
      url: 'https://api.maednutritions.com/api/Report/Meal_Plan',
      headers: {
        Authorization: 'Beare ' + token,
        'Content-Type': 'application/json',
      },
    };

    axios(config)
      .then(function (response) {
        setMealplan(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const handleMealprep = () =>{
    const token = localStorage.getItem('token');
    console.log(token);
    // var axios = require('axios');
    var config = {
      method: 'get',
      url: 'https://api.maednutritions.com/api/Report/Meal_Prep',
      headers: {
        Authorization: 'Beare ' + token,
        'Content-Type': 'application/json',
      },
    };

    axios(config)
      .then(function (response) {
        setMealprep(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const list = [
    {
      header: 'Customer',
      amount: customer,
      text: 'The amount of users using mead',
      icon: AiOutlineUser,
      color: 'bg-primary',
    },
    {
      header: 'Meal Plan',
      amount: mealplan,
      text: 'The amount of meal plans ordered',
      icon: MdOutlineRestaurantMenu,
      color: 'bg-secondary',
    },
    {
      header: 'Meal Prep',
      amount: mealprep,
      text: 'The amount of meal preparations ordered',
      icon: GiMeal,
      color: 'bg-red-500',
    },
  ];
  return (
    <div className="flex flex-col gap-5 pb-24 items-center">
      <Widgets widgetContent={list} />
      <div className="h-full w-full flex justify-center gap-5 flex-col items-center">
       <div className='text-center'>
        <h1 className='font-bold text-xl'>Meals Ordered In Graph</h1>
        <h1 className='font-light text-2xl'>which meal is ordered the most</h1>
        </div> 
        <BarMealChart />
      </div>
    </div>
  );
};

export default Dashboard;
