import { useState, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate as navigation } from 'react-router-dom';

const customerHandler = () => {
    const nav = navigation();
    const handleClick = (e) => {
        e.preventDefault();
        nav('/dash/mealorder');
    };
    return (
        <div>
            <FaArrowLeft
                onClick={handleClick}
                className="mx-11 m-5 text-3xl hover:text-green-500"
            />
            <div className="flex flex-col bg-gray-200 p-10 m-11 h-full">
                <div className="flex flex-row justify-between items-center">
                    <div className="space-y-4 text-xl">
                        <h1>Customer ID : </h1>
                        <h1>Subscription : </h1>
                        <h1>Bank Name : </h1>
                        <h1>Meal Type : </h1>
                        <h1>Status : </h1>
                        <h1>Transction : <span><input type='text' placeholder='Enter Transaction Code' className='text-lg w-72 ease-linear duration-200 focus:border-green-500 outline-none p-1 bg-transparent border-b-2 border-gray-400' /></span> </h1>
                        <h1>Meal preday : </h1>
                        <h1>Subscription Date : </h1>
                    </div>
                    <div className='w-1 rounded-xl h-96 bg-green-500' />
                    <div className="space-y-4 text-xl">
                        <h1>ID : </h1>
                        <h1>Full Name : </h1>
                        <h1>Phone Number : </h1>
                        <h1>Allergic : <span><input type='text' placeholder='Enter Users Allergic' className='text-lg w-72 ease-linear duration-200 focus:border-green-500 outline-none p-1 bg-transparent border-b-2 border-gray-400' /></span></h1>
                        <h1>Fasting Type : <span><input type='text' placeholder='Enter Fasting Type' className='text-lg w-72 ease-linear duration-200 focus:border-green-500 outline-none p-1 bg-transparent border-b-2 border-gray-400' /></span></h1>
                    </div>
                </div>
                <div className="flex flex-row justify-end space-x-7 text-xl">
                    <button className='text-red-500  p-1 px-2 hover:bg-red-500 hover:text-white rounded-md ease-linear duration-200'>Cancle</button>
                    <button className='text-green-500 p-1 px-2 hover:bg-green-500 hover:text-white rounded-md ease-linear duration-200'>Update</button>
                </div>
            </div>
        </div>
    );
};
export default customerHandler;
