import React from 'react';
import healthy from './../../Assets/meadFood.jpg';
import Loginform from './../../Controller/loginform';

const loginpage = () => {
  return (
    <div className="bg-neutral-300 min-h-screen flex justify-center items-center">
    <div className="rounded-lg flex flex-col sm:flex-row w-full max-w-[50rem]">
      <div className="relative hidden sm:block">
        <img src={healthy} alt="" className="rounded-tl-2xl rounded-bl-2xl w-full" />
      </div>
      <div className="bg-white rounded-tr-2xl rounded-br-2xl p-10 w-full sm:w-auto flex justify-center items-center">
        <Loginform />
      </div>
    </div>
  </div>
  
  
  );
};

export default loginpage;
