import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

const Navigation = () => {
  const navigate = useNavigate();
  const [unseenCount, setSeenCount] = useState();
  
    useEffect(() => {
      const token = localStorage.getItem('token');
      const config = {
        method: 'get',
        url: `https://api.maednutritions.com/api/Meal_plan/all`,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      };
      axios(config)
        .then(function (response) {
          console.log(response.data);
          setSeenCount(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }, []);
  
  const handleLogout = () => {
    window.location.href = '/';
    window.history.replaceState(null, '', '/');
  };

  const handleNav = (route) => {
    navigate(route);
  };
  const links = [
    { id: 1, link: 'Dashboard', route: '/dash' },
    { id: 2, link: 'Orders', route: 'mealorder' },
  ];
  return (
    <div className="h-screen w-screen">
      <div className="top-0 w-screen p-5 px-10 bg-primary flex justify-between">
        <div className="font-bold text-2xl text-white ">
          Mead Nutrition Admin Panel
        </div>
        <div className="flex space-x-5 items-center ">
          {links.map((items) => (
            <div
              key={items.id}
              className="text-white text-sm"
              onClick={() => handleNav(items.route)}
            >
              <span className="navgation cursor-pointer p-1 ease-out duration-100">
                {items.link}
                {items.link === 'Orders' && (
                  <span className="inline-block ml-2 bg-secondary rounded-full text-xs font-semibold text-black px-2">
                    {unseenCount}
                  </span>
                )}
              </span>
            </div>
          ))}
          <button
            onClick={handleLogout}
            className="border border-white text-white hover:border-red-400 p-1 w-20 rounded-lg transition-all"
          >
            Logout
          </button>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Navigation;
