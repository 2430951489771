import React from 'react';

const Widgets = ({ widgetContent }) => {
  return (
    <div className='flex flex-wrap justify-center'>
    {widgetContent.map((items, index) => (
      <div key={index} className="widget-container cursor-default flex m-5 justify-between rounded-lg p-4 w-full md:w-96 bg-white border border-neutral-400">
        <div className="flex flex-col justify-center items-start mx-2">
          <h1 className="text-2xl font-bold text-neutral-800">{items.header}</h1>
          <p className="font-light text-xl text-primary tracking-wider ">{items.amount}</p>
          <p className="font-light text-xs text-gray-400 ">
            {items.text}
          </p>
        </div>
        <div className={`mx-1 rounded-full p-2 ${items.color}`}>
          <items.icon className="text-4xl text-white" />
        </div>
      </div>
    ))}
  </div>
  
  );
};

export default Widgets;
