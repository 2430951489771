import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { CircleLoading } from '../Components/Loading/Loading';
import { BiError } from 'react-icons/bi';

Chart.register(...registerables);

const options = {
  responsive: false,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: true,
          drawBorder: false,
          borderDash: [3, 3],
          zeroLineColor: 'blue',
        },
        categoryPercentage: 0.7,
        barPercentage: 0.9,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    yAxes: [
      {
        display: false,
        gridLines: {
          display: false,
          zeroLineColor: 'transparent',
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const BarMealChart = () => {
  const [mealData, setMealData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    handleMealPlan('Keto Meal Plan');
    handleMealPlan('Paleo meal plan');
    handleMealPlan('Clean meal plan');
    handleMealPlan('High protein Vegan m');
    handleMealPlan('High protein Vegetar');
    handleMealPlan('Hagerigna meal plan');
  }, []);

  const handleMealPlan = (mealName) => {
    const token = localStorage.getItem('token');

    const apiUrl = `https://api.maednutritions.com/api/Report/Meal_Report/${mealName}`;

    var config = {
      method: 'get',
      url: apiUrl,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setMealData((prevData) => ({
          ...prevData,
          [mealName]: response.data,
        }));
        setLoading(false);
        setError(false);
      })
      .catch(function (error) {
        setLoading(false);
        setError(true);
        console.log(error);
      });
  };
  const data = {
    labels: [
      'Keto Meal Plan',
      'Paleo meal plan',
      'Clean meal plan',
      'High protein Vegan meal plan',
      'High protein Vegetarian meal plan',
      'Hagerigna meal plan',
    ],
    datasets: [
      {
        label: 'Meals Order per amount',
        data: [
          mealData['Keto Meal Plan'],
          mealData['Paleo meal plan'],
          mealData['Clean meal plan'],
          mealData['High protein Vegan m'],
          mealData['High protein Vegetar'],
          mealData['Hagerigna meal plan'],
        ],
        backgroundColor: '#04ab05',
      },
    ],
  };
  return (
    <>
      {isLoading ? (
        <>
          <CircleLoading />
          <h1>LOADING DATA</h1>
        </>
      ) : error ? (
        <>
          <div className="w-full flex justify-center h-full gap-3 mt-20 items-center flex-col">
            <BiError className="text-5xl text-red-500" />
            <h1>There was an error loading your data</h1>
          </div>
        </>
      ) : (
        <Bar
          className=""
          width={650}
          height={400}
          data={data}
          options={options}
        />
      )}
    </>
  );
};

export default BarMealChart;
